import React from 'react';
import styled from 'styled-components';
import { Layout, Title, Subtitle } from '../components';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.title.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.title.function};

  .body--loaded & {
    opacity: 1;
  }
`;

const SubtitleWrapper = styled.div`
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${props =>
    props.theme.animate.bodyLoaded.subtitle.duration};
  transition-timing-function: ${props =>
    props.theme.animate.bodyLoaded.subtitle.function};
  transition-delay: ${props => props.theme.animate.bodyLoaded.subtitle.delay};

  .body--loaded & {
    opacity: 1;
  }
`;

const Home = () => {
  return (
    <Layout>
      <Wrapper>
        <TitleWrapper>
          <Title />
        </TitleWrapper>
        <SubtitleWrapper>
          <Subtitle text="We bring the wisdom of the Torah online" />
        </SubtitleWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Home;
